/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import '@/bootstrap';
import formKitTheme from '@/formkitTheme';
import dayjs from '@/plugins/dayjs';
import localeMessages from '@/vue-i18n-locales.generated';
import {createAutoAnimatePlugin} from '@formkit/addons';
import {autoAnimatePlugin} from '@formkit/auto-animate/vue';
import {nl} from '@formkit/i18n';
import {generateClasses} from '@formkit/themes';
import {defaultConfig as FKdc, plugin as FKp} from '@formkit/vue';
import {createGtm} from '@gtm-support/vue-gtm';
import {createInertiaApp} from '@inertiajs/vue3';
import VueVideoPlayer from '@videojs-player/vue';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import Notifications from 'notiwind';
import {createPinia} from 'pinia';
import {createApp, h} from 'vue';
import {createI18n} from 'vue-i18n';
import '../css/app.css';
import {Ziggy} from './ziggy';
import {ZiggyVue} from './plugins/ziggy';
import {flareVue} from "@flareapp/vue";
import flare from './plugins/flare';

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}

createInertiaApp({
    progress: {color: '#FFF', delay: 500},
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            legacy: false,
            locale: currentLocale, // user locale by props
            fallbackLocale: 'en', // set fallback locale
            messages: localeMessages, // set locale messages
        });

        let createdApp = createApp({
            render: () => h(App, props),
        })
            .use(plugin)
            .use(pinia)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(flareVue)
            .use(dayjs, {locale: currentLocale})
            .use(Notifications)
            .use(VueVideoPlayer, {autoplay: true})
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [messageToHTMLPlugin, createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: 'nl',
                    globalInjection: true,
                    icons: {
                        search: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <path id="search_FILL0_wght400_GRAD0_opsz48_3_" data-name="search_FILL0_wght400_GRAD0_opsz48 (3)" d="M21.022,21.95l-5.844-5.844a4.9,4.9,0,0,1-1.556.9,5.5,5.5,0,0,1-1.889.322,5.533,5.533,0,0,1-4.067-1.667A5.481,5.481,0,0,1,6,11.639,5.481,5.481,0,0,1,7.667,7.617,5.507,5.507,0,0,1,11.711,5.95a5.444,5.444,0,0,1,4.011,1.667,5.687,5.687,0,0,1,1.344,5.867,5.641,5.641,0,0,1-.933,1.667L22,20.972Zm-9.311-5.956a4.159,4.159,0,0,0,3.067-1.278,4.214,4.214,0,0,0,1.267-3.078,4.214,4.214,0,0,0-1.267-3.078,4.159,4.159,0,0,0-3.067-1.278,4.363,4.363,0,0,0-4.378,4.356,4.363,4.363,0,0,0,4.378,4.356Z" transform="translate(-6 -5.95)" fill="#292929"/></svg> ',
                    },
                }),
            )
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled:
                        import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED ===
                        'true' && import.meta.env.PROD,
                }),
            )
            .use(i18n)
            .use(autoAnimatePlugin);

        if (import.meta.env.PROD) {
            createdApp;
        }

        return createdApp.mount(el);
    },
});

function messageToHTMLPlugin(node) {
    const icon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.818" viewBox="0 0 16 13.818"><path id="warning_FILL0_wght400_GRAD0_opsz48" d="M10.018,11.436ZM2,17.818,10,4l8,13.818Zm7.527-4.145h1.091V9.6H9.527Zm.545,2.018a.529.529,0,1,0-.391-.155A.53.53,0,0,0,10.073,15.691ZM3.891,16.727H16.109L10,6.182Z" transform="translate(-2 -4)" fill="#c30000"/></svg>';

    node.on('created', () => {
        const original = node.props.definition.schema;
        node.context.warningIcon = icon;
        node.props.definition.schema = (extensions) => {
            if (!extensions.message) {
                extensions.message = {
                    attrs: {
                        innerHTML: '$warningIcon + " " + $message.value',
                    },
                    children: null,
                };
            }
            return original(extensions);
        };
    });
}
